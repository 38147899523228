import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Content from "../components/content"
import SEO from "../components/seo"
import NewsPreview from '../components/news-preview'
import Header from "../components/header"
import ImageBar from "../components/ImageBar"
import Footer from "../components/footer"
import Img from "gatsby-image"

const HomePage = ({data}) => {
    const meta = data.graphAPI.homepage.meta
    const content = data.graphAPI.homepage.content
    return (
        <main>
            <div className="main">
              <Header />
              <ImageBar isHome="true" />
              <div className="container">
                <div className="main-content">
                    <SEO title={meta.title} />
                    <div style={{ marginTop: '-8rem'}} className="news-title">
                        <h2 className="title is-size-1">Latest News </h2>
                    </div>
                    <NewsPreview posts={data.graphAPI.blogPosts} />
                    <div className="has-text-centered" style={{margin: '-3rem 1rem 3rem'}}>
                        <p><strong>You've reached the end. <Link to="/archive">View News Archive</Link></strong></p>
                    </div>
                    <div className="title-section">
                        <h1 className="title is-size-1">{meta.title}</h1>
                    </div>
                    <div className="columns">
                    <div className="column is-8">
                    <Content content={content} isHome={false} />
                    </div>
                    {/* <div className="column is-3 is-offset-1">
                        <Link className="button is-dark mb-4 is-block" to="/en-election-information">Board of Directors Elections</Link>
                        <Link className="button is-dark is-block" to="/es-election-information">Elecciones de la Junta Directiva</Link>
                    </div> */}
                    </div>
                </div>
              </div>
              <Footer />
            </div>
        </main>
    )
}

export default HomePage

export const homePageQuery = graphql`
    query homePage {
        useIt: file(relativePath: {eq: "water-use-it-wisely.png"}) {
            childImageSharp {
                fluid(fit: CONTAIN, maxWidth: 200) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        twdb: file(relativePath: {eq: "TWDB_logo.png"}) {
            childImageSharp {
                fluid(fit: CONTAIN, maxWidth: 200) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        wateriq: file(relativePath: {eq: "wateriq_logo.png"}) {
            childImageSharp {
                fluid(fit: CONTAIN, maxWidth: 200) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        watersmart: file(relativePath: {eq: "logo_watersmart_20161218.png"}) {
            childImageSharp {
                fluid(fit: CONTAIN, maxWidth: 200) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        epa: file(relativePath: {eq: "epa-logo.png"}) {
            childImageSharp {
                fluid(fit: CONTAIN, maxWidth: 200) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        graphAPI {
            homepage {
                meta {
                    id
                    title
                    description
                }
                content {
                    ... on GRAPHAPI_ComponentPageBaseContent {
                        ...baseContent
                    }
                    ... on GRAPHAPI_ComponentPageImage {
                        ...pageImage
                    }
                    ... on GRAPHAPI_ComponentPageLinkCardWithImage {
                        ...linkCardWithImage
                    }
                    ... on GRAPHAPI_ComponentPageSplitContentImage {
                        ...splitContentImage
                    }
                    ... on GRAPHAPI_ComponentPageGallery {
                        ...pageGallery
                    }
                    ... on GRAPHAPI_ComponentPageLinkList {
                        ...pageLinkList
                    }
                }
            }
            blogPosts(sort: "publishedAt:desc") {
                id
                title
                featured
                description
                publishedAt
                expiresAt
                heroImage {
                    id
                    url
                    updated_at
                    documentFile {
                        childImageSharp {
                            fluid(maxWidth: 2048){
                                ...GatsbyImageSharpFluid_tracedSVG
                            }
                        }
                    }
                }
            }
        } 
    }
`


